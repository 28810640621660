<template>
  <Header />
  <main>
    <div class="cont">
      <FacilityName :facilityId="Number($route.query.facility_id)"/>
      <FacilityAdminMenu :facilityId="Number($route.query.facility_id)"/>
      <h1>施設担当者一覧</h1>
      <div class="admin">
        <table v-if="facilityAdminUsers.length > 0">
          <thead>
            <tr>
              <th>ログインID</th>
              <th>氏名</th>
              <th>ステータス</th>
              <th v-if="isSuperAdmin">編集</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in facilityAdminUsers" :key="user.id">
              <td>{{ user.email }}</td>
              <td>{{ user.name }}</td>
              <td>{{ renderStatus(user.status) }}</td>
              <td v-if="isSuperAdmin">
                <button v-on:click="editFacilityAdminUser(user.id)">編集</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import FacilityAdminMenu from './FacilityAdminMenu'
import FacilityName from './FacilityName'
import { apiGet } from '../../api'
import { userStatus } from '../../constants'

export default {
  name: 'FacilityAdminUsers',
  components: {
    Header,
    Footer,
    FacilityAdminMenu,
    FacilityName,
  },
  data() {
    return {
      facilityAdminUsers: [],
      isSuperAdmin: this.$router.app.userDetail.is_super_admin
    }
  },
  mounted() {
    this.loadFacilityAdminUsers()
  },
  methods: {
    loadFacilityAdminUsers: async function() {
      const response = await apiGet(`/api/user_facility_relation?facility_id=${this.$route.query.facility_id}`, this.$router.app.$cookies)
      this.facilityAdminUsers = response.data
    },
    editFacilityAdminUser: function(userDetailId) {
      this.$router.push(`/facility_admin/edit_user?user_detail_id=${userDetailId}&facility_id=${this.$route.query.facility_id}`)
    },
    renderStatus: function(status) {
      if (status === userStatus.active) {
        return '利用中'
      } else if (status === userStatus.inactive) {
        return '停止中'
      }
      return '不明'
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .cont .admin {
    display: inline-block;
    width: 100%;
    min-width: 60vw;
  }
  .cont .admin table {
    width: 100%;
    border: rgb(150,150,150) solid 1px;
    font-size: 3vw;
    border-collapse: collapse;
  }
  .cont .admin table thead th {
    background: rgb(75, 130, 200);
    text-align: center;
    color: rgb(255,255,255);
    padding: .2em .5em;
    border-right: rgb(255,255,255) solid 1px;
  }
  .cont .admin table thead th:last-child {
    border-right: none;
  }
  .cont .admin table td {
    padding: .2em .5em;
    border-right: rgb(200,200,200) solid 1px;
    border-bottom: rgb(200,200,200) solid 1px;
    background: rgb(255,255,255);
  }
  .cont .admin table td:last-child {
    border-right: none;
    text-align: center;
  }
  .cont .admin table tr:nth-of-type(2n) td {
    background: rgb(240, 245, 255);
  }
  .cont .admin table button {
    padding: .4em;
  }
}
@media all and (min-width: 480px) {
  .cont .admin {
    width: 60vw;
    margin: auto;
  }
  .cont .admin table {
    width: 100%;
    border: rgb(150,150,150) solid 1px;
    font-size: 1vw;
    border-collapse: collapse;
  }
  .cont .admin table thead th {
    background: rgb(75, 130, 200);
    text-align: center;
    color: rgb(255,255,255);
    padding: .2em .5em;
    border-right: rgb(255,255,255) solid 1px;
  }
  .cont .admin table thead th:last-child {
    border-right: none;
  }
  .cont .admin table td {
    padding: .2em .5em;
    border-right: rgb(200,200,200) solid 1px;
    border-bottom: rgb(200,200,200) solid 1px;
    background: rgb(255,255,255);
  }
  .cont .admin table td:last-child {
    border-right: none;
    text-align: center;
  }
  .cont .admin table tr:nth-of-type(2n) td {
    background: rgb(240, 245, 255);
  }
}
</style>

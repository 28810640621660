<template>
  <Header />
    <main>
      <div class="cont">
        <div class="fsm btns">
          <p><button v-on:click="showFacilityManager">ダッシュボードに戻る</button></p>
          <p v-if="isSuperAdmin"><button v-on:click="showFacilityDetail">施設詳細</button></p>
          <p><button v-on:click="showResidents">入居者一覧</button></p>
          <p><button v-on:click="showFacilityAdmins">施設担当者一覧</button></p>
          <p><button v-on:click="showFacilityLabels">ラベル・備考</button></p>
        </div>
      </div>
    </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'

export default {
  name: 'FacilityConfigMenu',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      facilityId: this.$route.query.id,
      isSuperAdmin: this.$router.app.userDetail.is_super_admin
    }
  },
  methods: {
    showFacilityManager: function() {
      this.$router.push(`/facility_manager?id=${this.facilityId}`)
    },
    showFacilityDetail: function() {
      this.$router.push(`/facility_admin/facility_detail?facility_id=${this.facilityId}`)
    },
    showResidents: function() {
      this.$router.push(`/facility_admin/residents?facility_id=${this.facilityId}`)
    },
    showFacilityAdmins: function() {
      this.$router.push(`/facility_admin/users?facility_id=${this.facilityId}`)
    },
    showFacilityLabels: function() {
      this.$router.push(`/facility_admin/facility_labels?facility_id=${this.facilityId}`)
    },
  }
}
</script>
<style scoped>
@media all and (max-width: 480px) {
  .fsm {
    width: 80%;
    margin: auto;
    padding: 10vw 0 0;
  }
  .fsm p {
    padding-bottom: 8vw;
  }
  .fsm p button {
    width: 100%;
  }
}
@media all and (min-width: 480px) {
  .fsm {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 80vw;
    margin: auto;
  }
  .fsm p {
    width: 18%;
    padding-bottom: 4em;
  }
  .fsm p button {
    width: 100%;
  }
}
</style>

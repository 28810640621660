import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import { apiGet } from './api'
import jwt_decode from 'jwt-decode'

import NotFound from './components/NotFound'
import Login from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
import FacilityManager from './components/FacilityManager/FacilityManager'
import TargetUserDetailRealTime from './components/FacilityManager/TargetUserDetail/RealTime'
import TargetUserDetailTimeline from './components/FacilityManager/TargetUserDetail/Timeline'
import FacilityConfigMenu from './components/FacilityManager/FacilityConfigMenu'
import SelectFacility from './components/SelectFacility/SelectFacility'
import LogDownload from './components/LogDownload/LogDownload'
import FacilityAdminFacilityDetail from './components/FacilityAdmin/FacilityDetail'
import FacilityAdminResidents from './components/FacilityAdmin/Residents'
import FacilityAdminEditResident from './components/FacilityAdmin/EditResident'
import FacilityAdminUsers from './components/FacilityAdmin/Users'
import FacilityAdminEditUser from './components/FacilityAdmin/EditUser'
import FacilityAdminFacilityLabels from './components/FacilityAdmin/FacilityLabels'
import AdminTop from './components/Admin/AdminTop'
import CompanyMaster from './components/Admin/CompanyMaster'
import EditCompany from './components/Admin/EditCompany'
import Help from './components/Help'
import Inquiry from './components/Inquiry'
import TermOfUse from './components/TermOfUse'
import PrivacyPolicy from './components/PrivacyPolicy'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/select_facility' },
    { path: '/:notFound(.*)', component: NotFound },
    { name: 'Login', path: '/login', component: Login },
    { name: 'ForgotPassword', path: '/forgot_password', component: ForgotPassword },
    { name: 'ResetPassword', path: '/reset_password', component: ResetPassword },
    { name: 'SelectFacility', path: '/select_facility', component: SelectFacility, beforeEnter: requireAuth},
    { name: 'FacilityManager', path: '/facility_manager', component: FacilityManager, beforeEnter: requireAuth},
    { name: 'TargetUserDetailRealTime', path: '/target_user_detail/real_time', component: TargetUserDetailRealTime, beforeEnter: requireAuth},
    { name: 'TargetUserDetailTimeline', path: '/target_user_detail/timeline', component: TargetUserDetailTimeline, beforeEnter: requireAuth},
    { name: 'FacilityConfigMenu', path: '/facility_config_menu', component: FacilityConfigMenu, beforeEnter: requireAuth},
    { name: 'LogDownload', path: '/log_download', component: LogDownload, beforeEnter: requireAuth},
    { name: 'FacilityDetail', path: '/facility_admin/facility_detail', component: FacilityAdminFacilityDetail, beforeEnter: requireSuperAdmin},
    { name: 'FacilityAdminResidents', path: '/facility_admin/residents', component: FacilityAdminResidents, beforeEnter: requireAuth},
    { name: 'FacilityAdminEditResident', path: '/facility_admin/edit_resident', component: FacilityAdminEditResident, beforeEnter: requireAuth},
    { name: 'FacilityAdminUsers', path: '/facility_admin/users', component: FacilityAdminUsers, beforeEnter: requireAuth},
    { name: 'FacilityAdminEditUser', path: '/facility_admin/edit_user', component: FacilityAdminEditUser, beforeEnter: requireSuperAdmin},
    { name: 'FacilityAdminFacilityLabels', path: '/facility_admin/facility_labels', component: FacilityAdminFacilityLabels, beforeEnter: requireAuth},
    { name: 'AdminTop', path: '/admin/', component: AdminTop, beforeEnter: requireAdmin},
    { name: 'CompanyMaster', path: '/admin/company_master', component: CompanyMaster, beforeEnter: requireAdmin},
    { name: 'EditCompany', path: '/admin/edit_company', component: EditCompany, beforeEnter: requireAdmin},
    { name: 'Help', path: '/help', component: Help},
    { name: 'Inquiry', path: '/inquiry', component: Inquiry},
    { name: 'TermOfUse', path: '/term_of_use', component: TermOfUse},
    { name: 'PrivacyPolicy', path: '/privacy_policy', component: PrivacyPolicy}
  ],
})

async function requireAuth(to, from, next) {
  const { $cookies } = router.app.config.globalProperties
  let accessToken = $cookies.get('AccessToken')
  let refreshToken = $cookies.get('RefreshToken')

  try {
    if (accessToken === null || refreshToken === null) {
      return redirectToLogin(to, next)
    }
    accessToken = await _tokenRefreshIfNeeded($cookies)

    const verifyTokenResponse = await axios.post('/api/auth/verify_token', {
      access_token: accessToken,
    })

    if (verifyTokenResponse.data.success) {
      const userDetailResponse = await apiGet(`/api/user_detail/me`, $cookies)
      router.app.userDetail = userDetailResponse.data
      next()
    } else {
      return redirectToLogin(to, next)
    }
  } catch(err) {
    console.log(err)
    return redirectToLogin(to, next)
  }
}

//TODO: change name admin -> master
async function requireAdmin(to, from, next) {
  const { $cookies } = router.app.config.globalProperties
  let accessToken = $cookies.get('AccessToken')
  let refreshToken = $cookies.get('RefreshToken')

  try {
    if (accessToken === null || refreshToken === null) {
      return redirectToLogin(to, next)
    }
    accessToken = await _tokenRefreshIfNeeded($cookies)

    const verifyTokenResponse = await axios.post('/api/auth/verify_token', {
      access_token: accessToken,
    })
    if (!verifyTokenResponse.data.success){
      return redirectToLogin(to, next)
    }

    const userDetailResponse = await apiGet(`/api/user_detail/me`, $cookies)
    if (userDetailResponse.data.is_admin) {
      return next()
    } else {
      return redirectToUserTop(next)
    }
  } catch {
    return redirectToLogin(to, next)
  }
}

async function requireSuperAdmin(to, from, next) {
  const { $cookies } = router.app.config.globalProperties
  let accessToken = $cookies.get('AccessToken')
  let refreshToken = $cookies.get('RefreshToken')

  try {
    if (accessToken === null || refreshToken === null) {
      return redirectToLogin(to, next)
    }
    accessToken = await _tokenRefreshIfNeeded($cookies)

    const verifyTokenResponse = await axios.post('/api/auth/verify_token', {
      access_token: accessToken,
    })
    if (!verifyTokenResponse.data.success){
      return redirectToLogin(to, next)
    }

    const userDetailResponse = await apiGet(`/api/user_detail/me`, $cookies)
    if (userDetailResponse.data.is_super_admin) {
      router.app.userDetail = userDetailResponse.data
      return next()
    } else {
      return redirectToUserTop(next)
    }
  } catch {
    return redirectToLogin(to, next)
  }
}

function redirectToLogin(to, next) {
  next({
    path: '/login',
    query: { redirect: to.fullPath }
  })
}

function redirectToUserTop(next) {
  next({
    path: '/select_facility'
  })
}

async function _tokenRefreshIfNeeded(cookies) {
  let accessToken = cookies.get('AccessToken')
  let refreshToken = cookies.get('RefreshToken')

  const decoded = jwt_decode(accessToken, {complete: true})
  const exp = decoded.exp * 1000
  const now = new Date().getTime()
  if (now > exp) {
    const refreshResponse = await axios.post('/api/auth/refresh', {
      access_token: accessToken,
      refresh_token: refreshToken
    })
    accessToken = refreshResponse.data.access_token
    refreshToken = refreshResponse.data.refresh_token
    cookies.config(60 * 60 * 24 * 30,'')
    cookies.set('AccessToken', accessToken)
    cookies.set('RefreshToken', refreshToken)
  }
  return accessToken
}

export default router;

<template>
  <main class="idx">
    <p class="caution">※CareSenseは医療機器ではありませんので、表記されている値やグラフは推定値を含み正確なものではありません。</p>
    <div class="idx-login">
      <dl>
        <dt><img src="@/assets/img/logo-mark.svg" alt="AI Care" /></dt>
        <dd>
          <img src="@/assets/img/sl-logo-blue.svg" />
          <div class="message">
            <span v-if="this.$route.query.reset_password_success">パスワードがリセットされました</span>
            <span v-if="showLoginError">メールアドレスかパスワードが間違っています</span>
          </div>
          <div class="form-item">
            <label for="email">メールアドレス</label>
            <input id="email" placeholder="メールアドレス" autocomplete="off" type="text" v-model="email">
          </div>
          <div class="form-item">
            <label for="password">パスワード</label>
            <input id="password" placeholder="パスワード" autocomplete="off" type="password" v-model="password">
          </div>
          <div class="form-item">
            <button class="button" @click="handle()">ログイン</button>
          </div>
          <div class="form-item">
            <button class="button" @click="forgotPassword()">パスワードを忘れた</button>
          </div>
        </dd>
      </dl>
    </div>
  </main>
  <Footer />
</template>

<script>
import axios from 'axios'
import { apiGet } from '../api'
import Footer from './Footer'

export default {
  name: 'Login',
  components: {
    Footer,
  },
  data () {
    return {
      email: '',
      password: '',
      showLoginError: false,
    }
  },
  async mounted () {
    if (this.$router.app.$cookies.get('AccessToken') === null) {
      return
    }
    const userDetailResponse = await apiGet('/api/user_detail/me', this.$router.app.$cookies)
    if (!userDetailResponse.data) {
      return
    }
    this.redirectToTop(userDetailResponse.data.is_admin)
  },
  methods: {
    handle: async function() {
      try {
        const loginResponse = await axios.post('/api/auth/login', {
          email: this.email,
          password: this.password,
        })

        if (loginResponse.data.success) {
          this.$router.app.$cookies.config(60 * 60 * 24 * 30,'')
          this.$router.app.$cookies.set('AccessToken', loginResponse.data.accessToken)
          this.$router.app.$cookies.set('RefreshToken', loginResponse.data.refreshToken)
          if ('redirect' in this.$route.query) {
            this.$router.push(this.$route.query.redirect)
          } else {
            const userDetailResponse = await apiGet('/api/user_detail/me', this.$router.app.$cookies)
            this.redirectToTop(userDetailResponse.data.is_admin)
          }
        } else {
          this.showLoginError = true
        }
      } catch(err) {
        console.error(err)
      }
    },
    forgotPassword: function() {
      this.$router.push('/forgot_password')
    },
    redirectToTop: function(isAdmin) {
      if (isAdmin) {
        this.$router.push('/admin')
      } else {
        this.$router.push('/select_facility')
      }
    }
  }
}
</script>

<style scoped>
main {
  position: relative;
}
.form-item {
  margin: 0 auto;
  text-align: center;
}

label {
  display: block;
}

input {
  width: 50%;
  padding: .5em;
  font: inherit;
}

button {
  padding: 0.5em;
  margin: 1em;
}
@media all and (max-width: 480px) {
  .caution {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 2em;
    text-align: center;
    font-size: 3vw;
  }
}

@media all and (min-width: 480px) {
  .caution {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 1vw;
  }
}
</style>
